import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import queryString from 'query-string';

import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import HumanService from 'mangools-commons/dist/services/HumanService';

import { parseAddKeywords, parseDetail, parseKeywords } from 'sources/parsers/TrackingKeywordsDataParser';
import DateFormatService from 'services/DateFormatService';

// Constants
const TRACKINGS_ENDPOINT = '/trackings';
const ENDPOINT = 'tracked-keywords';
const SNAPSHOT_ENDPOINT = '/serps';

/**
 * Data source class handling communication with API for tracked keywords data.
 */
class TrackingKeywordsSource {
    static getDetail({ accessToken, report, shareToken }, { trackingId, id, from, to }) {
        const fromWithTZ = DateFormatService.formatISOWithCurrentTZ(from);
        const toWithTZ = DateFormatService.formatISOWithCurrentTZ(to);
        let query = null;
        let headers = {};

        if (report === true) {
            query = queryString.stringify({ from: fromWithTZ, to: toWithTZ, share_token: shareToken });
        } else {
            query = queryString.stringify({ from: fromWithTZ, to: toWithTZ });
            headers = {
                'X-Access-Token': accessToken,
            };
        }

        /* eslint-disable max-len */
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}/${ENDPOINT}/${id}?${query}`;
        /* eslint-enable max-len */

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseDetail(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete(accessToken, { trackingId, keywordIds }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}/${ENDPOINT}`;

        const requestBody = {
            tracked_keyword_ids: keywordIds,
        };

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: keywordIds,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static restore(accessToken, { trackingId, keywordIds }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}/${ENDPOINT}/restore`;

        const requestBody = {
            tracked_keyword_ids: keywordIds,
        };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: keywordIds,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static add(accessToken, { trackingId, keywords, tagIds, lastDateInHistory }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}/${ENDPOINT}`;

        const requestBody = { keywords, tag_ids: tagIds, last_date_in_history: lastDateInHistory };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseAddKeywords(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getAllKeywords(accessToken, { trackingId }) {
        const query = queryString.stringify({ fields: ['kw'] });
        const headers = {
            'X-Access-Token': accessToken,
        };

        /* eslint-disable max-len */
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}/${ENDPOINT}?${query}`;
        /* eslint-enable max-len */

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers,
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseKeywords(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getSnapshot({ accessToken, serpSnapshotId }) {
        // eslint-disable-next-line max-len
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.SERPCHECKER_API_PATH}${SNAPSHOT_ENDPOINT}/${serpSnapshotId}/snapshot`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: data,
                        }))
                        .catch(ex => ({
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    error: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default TrackingKeywordsSource;
