import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import { parseCreate } from 'sources/parsers/AnnotationDataParser';

// Constants
const TRACKINGS_ENDPOINT = '/trackings';
const ENDPOINT = '/annotations';

/**
 * Data source class handling communication with API for reports data.
 */
class AnnotationSource {
    static create({ accessToken, trackingId }, { annotationText, date, nonShareable }) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}`;

        // Generate body data
        const requestBody = {
            date,
            text: annotationText,
            nonShareable,
        };

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseCreate(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static update({ accessToken, trackingId, annotationId }, { annotationText, nonShareable }) {
        /* eslint-disable max-len */
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/${annotationId}`;
        /* eslint-enable max-len */

        // Generate body data
        const requestBody = {
            text: annotationText,
            nonShareable,
        };

        return fetch(requestURL, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static delete({ accessToken, trackingId, annotationId }) {
        /* eslint-disable max-len */
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${TRACKINGS_ENDPOINT}/${trackingId}${ENDPOINT}/${annotationId}`;
        /* eslint-enable max-len */

        return fetch(requestURL, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default AnnotationSource;
