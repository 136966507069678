import { call, spawn, put, select, take, takeLatest } from 'redux-saga/effects';
import DOMService from 'mangools-commons/dist/services/DOMService';
import AuthService from 'mangools-commons/dist/services/AuthService';
import UsercomService from 'mangools-commons/dist/services/UsercomService';
import { currentQuerySelector, currentRouteSelector } from 'selectors/commonSelectors';

import {
    userPlanTypeSelector,
    ssoTicketSelector,
    loginTokenSelector,
    accessTokenSelector,
    idSelector,
} from 'selectors/userSelectors';

import { currentColorSchemeSelector } from 'selectors/uiSelectors';

import { requestedHideAuthTokensAction } from 'actions/routerActions';
import { setAuthTokens, skippedUserAction } from 'actions/userActions';
import { gtmTrack } from 'actions/analyticsActions';

import { setNavigatedInternally, showNeedToSignInMessage } from 'actions/uiActions';

import { requestedTrackingsAction } from 'actions/dataActions';
import { fetchLimitData, fetchUserData } from 'sagas/userSagas';
import { fetchAfterLoginData } from 'sagas/dataSagas';
import { initAnalytics } from 'sagas/analyticsSagas';
import { redirectToAuth } from 'sagas/routerSagas';

import RouterService from 'services/RouterService';
import ActionTypes from 'constants/ActionTypes';
import RoutePaths from 'constants/RoutePaths';
import UserPlanTypes from 'constants/UserPlanTypes';
import store, { cleanPreviousState } from 'lib/store';
import { analyticsEvents } from 'constants/analytics';
import { userEventNames } from 'constants/usercom';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';
import { colorSchemeSelector } from 'kwfinder/src/selectors/uiSelectors';

function* afterUserFlow() {
    if (!IS_CUSTOM_DOMAIN) {
        // 5. Request limit data
        yield spawn(fetchLimitData);
        // 6. Setup analytics stuff
        yield spawn(initAnalytics);
        // 7. Now fetch remaining after login data (user's lists, history)
        // will be skipped if we dont have access token
        yield spawn(fetchAfterLoginData);
    }

    // 8. Delete prev. version LocalStorage to keep it clean
    yield spawn(cleanPreviousState);

    // 9. Set email and initialize chat, or skip when on report
    const pathname = yield select(currentRouteSelector);
    const isReport = yield call(RouterService.isSame, pathname, RoutePaths.REPORT);

    if (isReport || IS_CUSTOM_DOMAIN) {
        DOMService.darkModeOff();
    }

    if (isReport === false && IS_CUSTOM_DOMAIN === false) {
        const userId = yield select(idSelector);

        yield spawn(UsercomService.initChatWidet, {
            userId,
            apiKey: process.env.USERCOM_CHAT_WIDGET_API_KEY,
            subdomain: process.env.USER_COM_SUBDOMAIN,
            onPayloadReceived: payload => {
                if (payload === userEventNames.SW_NEED_MORE_KEYWORDS_MSG_RECEIVED) {
                    // NOTE: this is workaround to run external callback in saga context
                    // https://github.com/redux-saga/redux-saga/issues/79#issuecomment-289518112
                    store.dispatch(gtmTrack({ event: analyticsEvents.NEED_MORE_KEYWORDS_CHAT_MSG }));
                }
            },
        });
    }

    // 10. if there are no token params in the query and accessToken is null it means
    // the /system/me endpoint returned null, thus our tokens are invalid and we need to redirect to mangools.com
    const query = yield select(currentQuerySelector);
    const accessToken = yield select(accessTokenSelector);
    const auth = new AuthService(query, { ssoTicket: null, loginToken: null });

    if (!IS_CUSTOM_DOMAIN) {
        if (auth.hasInvalidStoredTokens(accessToken)) {
            yield call(redirectToAuth);
        }

        // 11. Get plan type and load trackings, conditionally show sign in message
        const planType = yield select(userPlanTypeSelector);

        if (planType === UserPlanTypes.NO_PLAN) {
            if (isReport === false && auth.hasEmptyQueryTokens()) {
                // Expired plan, show need to upgrade message
                yield put(showNeedToSignInMessage());
            }
        } else {
            // Get trackings data if everything is OK
            yield put(requestedTrackingsAction());
        }

        // 12. Hide tokens from url
        yield put(requestedHideAuthTokensAction());
    }
}

function* watchUserFetched() {
    yield takeLatest(ActionTypes.DATA_USER_DATA_RECEIVED, afterUserFlow);
}

function* fetchUser() {
    yield spawn(watchUserFetched);
    yield call(fetchUserData, null, false);
}

function* authFlow() {
    if (IS_CUSTOM_DOMAIN) {
        yield put(skippedUserAction());
        yield call(afterUserFlow);
        return;
    }
    const query = yield select(currentQuerySelector);
    const ssoTicket = yield select(ssoTicketSelector);
    const loginToken = yield select(loginTokenSelector);
    const auth = new AuthService(query, { ssoTicket, loginToken });

    if (auth.hasEmptyQueryTokens()) {
        // if sso_ticket and login_token params are missing e.g. user is logged out
        // we want to show sign in message by calling fetchUserData which triggers afterUserFlow
        yield put(skippedUserAction());
        yield call(afterUserFlow);
    } else if (auth.hasQueryTokens()) {
        // if sso_ticket and login_token params are present
        // save them to store and fetch user
        yield put(
            setAuthTokens({
                ssoTicket: query.sso_ticket,
                loginToken: query.login_token,
            }),
        );

        yield call(fetchUser);
    } else if (!auth.hasStoredTokens()) {
        // if either ssoTicket or loginToken isn't present in the store
        // redirect to auth
        yield call(redirectToAuth);
    } else if (auth.hasStoredTokens()) {
        // if ssoTicket and loginToken are both present in the store fetch the user
        yield call(fetchUser);
    }
}

export function* initFlow() {
    const colorScheme = yield select(colorSchemeSelector);

    if (DOMService.isDarkMode(colorScheme)) {
        DOMService.darkModeOn();
    }

    yield call(authFlow);
}

/**
 * Internall navigation flow
 */
export function* internalNavigationFlow() {
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield take(ActionTypes.ROUTER_LOCATION_CHANGE);
    yield put(setNavigatedInternally());
}
