import Urls from 'mangools-commons/dist/constants/Urls';

export const HELP_DROPDOWN_ITEMS = [
    {
        label: 'Guides on our blog',
        items: [
            {
                label: 'How to: SERPWatcher guide',
                link: `${Urls.SERPWATCHER_GUIDE_URL}?ref=header-app-sw`,
                description: '27 min read',
            },
            {
                label: 'Keyword research guide',
                link: `${Urls.MANGOOLS_KEYWORD_RESEARCH_GUIDE_URL}?ref=header-app-sw`,
                description: '58 min read',
            },
            {
                label: 'SEO guide for beginners',
                link: `${Urls.MANGOOLS_SEO_ACADEMY_URL}?ref=header-app-sw`,
                description: '129 min read',
            },
        ],
    },
    {
        label: 'Other',
        items: [
            {
                label: 'Mangools Blog',
                link: `${Urls.MANGOOLS_BLOG_URL}?ref=header-app-sw`,
            },
            {
                label: 'Affiliate program',
                link: `${Urls.MANGOOLS_AFFILIATE_URL}?ref=header-app-sw`,
            },
            {
                label: 'FAQ',
                link: `${Urls.MANGOOLS_FAQ_URL}?ref=header-app-sw`,
            },
        ],
    },
];
