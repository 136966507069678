import React, { useCallback, useEffect, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';
import DateRangePicker from 'components/other/DateRangePicker';
import TimeframeService from 'services/TimeframeService';
import TimeframePresets from 'constants/TimeframePresets';
import TimeframeType from 'types/TimeframeType';

const TimeframeDropdown = ({
    currentTrackingCreatedAt,
    isTimeframeModal,
    onChange,
    onClose,
    getStart,
    timeframe,
    visible,
}) => {
    const [fromState, setFromState] = useState(timeframe.from);
    const [toState, setToState] = useState(timeframe.to);
    const [typeState, setTypeState] = useState(timeframe.type);

    useEffect(() => {
        if (
            !timeframe.from.isSame(fromState) ||
            !timeframe.to.isSame(toState) ||
            timeframe.type !== typeState
        ) {
            setFromState(timeframe.from);
            setToState(timeframe.to);
            setTypeState(timeframe.type);
        }
    }, [timeframe]); // Removed fromState, toState, typeState from dependencies

    const handleTimeframeChange = useCallback(
        ({ from, to }) => {
            const translated = TimeframeService.translate({ from, to });
            setFromState(translated.from);
            setToState(isNil(to) ? null : translated.to);
            setTypeState(translated.type);
            if (!isTimeframeModal) {
                onChange({
                    from: translated.from,
                    to: isNil(to) ? null : translated.to,
                    type: translated.type,
                    close: false,
                });
            }
        },
        [isTimeframeModal, onChange]
    );

    const handleSetTimeframeClick = useCallback(() => {
        onClose();
        if (!isNil(fromState) && !isNil(toState) && !isNil(typeState)) {
            onChange({ from: fromState, to: toState, type: typeState });
        } else if (!isNil(fromState)) {
            const translated = TimeframeService.translate({ from: fromState, to: fromState });
            onChange({ from: translated.from, to: translated.to, type: translated.type });
        }
    }, [onClose, fromState, toState, typeState, onChange]);

    const handlePresetButtonClick = useCallback(
        (presetType) => {
            if (
                typeState !== presetType &&
                TimeframeService.isPresetAvailable(currentTrackingCreatedAt, presetType)
            ) {
                const translated = TimeframeService.createFromType({
                    createdAt: currentTrackingCreatedAt,
                    type: presetType,
                });
                setFromState(translated.from);
                setToState(translated.to);
                setTypeState(translated.type);
                if (!isTimeframeModal) {
                    onChange({ from: translated.from, to: translated.to, type: translated.type, close: false });
                }
            }
        },
        [typeState, currentTrackingCreatedAt, isTimeframeModal, onChange]
    );

    const renderPresetButton = (label, presetType) => {
        return (
            <li key={presetType} className={classnames({ 'is-active': typeState === presetType })}>
                <button onClick={() => handlePresetButtonClick(presetType)} type="button">
                    {label}
                </button>
            </li>
        );
    };

    if (!visible) {
        return null;
    }

    return (
        <aside
            className={classnames(
                'uk-dropdown-right',
                'uk-dropdown-align-right',
                'uk-display-block',
                'uk-flex-item-none',
                {
                    'uk-dropdown': isTimeframeModal,
                    'mg-card': !isTimeframeModal,
                    'mg-margin-b-15': !isTimeframeModal,
                }
            )}
            style={isTimeframeModal ? { width: '583px', top: '28px' } : {}}
        >
            {isTimeframeModal && (
                <header className="mg-dropdown-header mg-padding-0-15">
                    <h1 className="color-black font-16 uk-text-bold uk-margin-remove">Choose a timeframe</h1>
                    <button onClick={onClose} className="uk-visible-small mg-close" type="button">
                        <FontAwesomeIcon icon="times" aria-label="Close" />
                    </button>
                </header>
            )}
            <div className="uk-flex uk-flex-wrap uk-flex-middle">
                <ul
                    className="mg-listnav is-xsmall is-light font-12 mg-card"
                    style={{ maxHeight: 'none', overflow: 'visible' }}
                >
                    {renderPresetButton('Today', TimeframePresets.TODAY)}
                    {renderPresetButton('This month', TimeframePresets.THIS_MONTH)}
                    {renderPresetButton('Last month', TimeframePresets.LAST_MONTH)}
                    {renderPresetButton('This week', TimeframePresets.THIS_WEEK)}
                    {renderPresetButton('Last week', TimeframePresets.LAST_WEEK)}
                    {renderPresetButton('Last 7 days', TimeframePresets.LAST_7_DAYS)}
                    {renderPresetButton('Last 30 days', TimeframePresets.LAST_30_DAYS)}
                    {renderPresetButton('Entire history', TimeframePresets.ENTIRE_HISTORY)}
                    <li />
                </ul>
                <div className="mg-padding-15 mg-margin-l-15 font-12 uk-clearfix uk-flex-item-auto">
                    <DateRangePicker
                        from={fromState}
                        onChange={handleTimeframeChange}
                        start={getStart()}
                        to={toState}
                    />
                </div>
            </div>
            {isTimeframeModal && (
                <div className="mg-dropdown-footer mg-padding-0-30 mg-padding-15-0 uk-text-center">
                    <button
                        className="mg-btn is-blue is-xsmall uk-width-medium-1-2 uk-width-1-1"
                        onClick={handleSetTimeframeClick}
                        type="button"
                    >
                        <FontAwesomeIcon icon="check" aria-hidden="true" />
                        Set timeframe
                    </button>
                </div>
            )}
        </aside>
    );
};

TimeframeDropdown.propTypes = {
    currentTrackingCreatedAt: number,
    isTimeframeModal: bool.isRequired,
    onChange: func.isRequired,
    onClose: func.isRequired,
    getStart: func.isRequired,
    timeframe: TimeframeType.isRequired,
    visible: bool.isRequired,
};

export default TimeframeDropdown;
