import { Provider } from 'react-redux';
import store from 'lib/store';
import { Helmet } from 'react-helmet';
import FlagProvider from '@unleash/proxy-client-react';
import { unleashClient } from 'mangools-commons/dist/configs/unleash';
import AppContainer from 'components/AppContainer';
import React from 'react';
import { WindowProvider } from 'mangools-react-components/src';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';

const isStaging = process.env.NODE_ENV === 'staging';
const isProduction = process.env.NODE_ENV === 'production';

export const App = () => {
    const application = () =>
        IS_CUSTOM_DOMAIN ? (
            <WindowProvider>
                <AppContainer />
            </WindowProvider>
        ) : (
            <FlagProvider unleashClient={unleashClient}>
                <WindowProvider>
                    <AppContainer />
                </WindowProvider>
            </FlagProvider>
        );
    return (
        <Provider store={store}>
            {(isStaging || isProduction) && (
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                        <meta httpEquiv="Content-Security-Policy" content="block-all-mixed-content" />
                        <meta httpEquiv="cache-control" content="max-age=0" />
                        <meta httpEquiv="cache-control" content="no-cache" />
                        <meta httpEquiv="expires" content="0" />
                        <meta httpEquiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
                        <meta httpEquiv="pragma" content="no-cache" />
                        <meta name="robots" content="noindex, nofollow" />
                        <meta
                            name="viewport"
                            content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                        />
                        {!IS_CUSTOM_DOMAIN && (
                            <meta
                                name="description"
                                content="SERPWatcher is an SEO rank tracking tool that makes other complicated tools look like from the 80s. Get started in 30 seconds. Try it now for free!"
                            />
                        )}
                        <meta name="theme-color" content="#7022b3" />
                        <meta name="msapplication-navbutton-color" content="#7022b3" />
                        <meta name="apple-mobile-web-app-capable" content="yes" />
                        <meta name="apple-mobile-web-app-status-bar-style" content="#7022b3" />
                        <meta name="google" content="notranslate" />
                        {!IS_CUSTOM_DOMAIN && <title>SERPWatcher by Mangools: The Most Effective Rank Tracker</title>}
                        {!IS_CUSTOM_DOMAIN && (
                            <link
                                rel="shortcut icon"
                                href="/serpwatcher/favicon.ico"
                                data-react-helmet="true"
                            />
                        )}
                        {!IS_CUSTOM_DOMAIN && <link rel="manifest" href="/serpwatcher/manifest.webmanifest" />}
                        <link
                            rel="stylesheet"
                            href="https://fonts.googleapis.com/css?family=Roboto:400,700,900&subset=latin,latin-ext"
                            type="text/css"
                        />
                        {!IS_CUSTOM_DOMAIN && (
                            <meta property="og:image" content="https://serpwatcher.com/images/serpwatcher-big.png" />
                        )}
                    </Helmet>
                    <noscript>
                        <header className="mg-header">
                            <div className="uk-float-left bg-lightcolor uk-height-1-1">
                                <span className="color-black uk-display-inline-block">
                                    <strong className="mg-margin-r-10 font-14">SERPWatcher</strong>
                                </span>
                            </div>
                        </header>
                        <div className="bg-lightgrey uk-position-cover mg-padding-90-0 uk-flex uk-flex-middle uk-flex-center uk-text-center">
                            <div>
                                <h1 className="uk-text-bold font-36 font-small-24 mg-margin-b-30">
                                    To use SERPWatcher, please enable JavaScript.
                                </h1>
                            </div>
                        </div>
                    </noscript>
                </>
            )}
            {!IS_CUSTOM_DOMAIN && (
                <Helmet>
                    <script
                        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
                    />
                </Helmet>
            )}
            {application()}
        </Provider>
    );
};
