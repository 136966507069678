import queryString from 'query-string';
import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import { API_VERSION, APP_CONFIG } from 'mangools-commons/dist/configs/app';
import { parse, parseSettings } from 'sources/parsers/UserDataParser';
import { parse as parseAccountData } from 'sources/parsers/AccountDataParser';
import { isNil, omit } from 'ramda';

const USER_ENDPOINT = `/${API_VERSION}/system/me`;
const CURRENT_USER_ENDPOINT = `/${API_VERSION}/mangools/current-user`;
const LIMITS_ENDPOINT = '/limits';
const CUSTOMIZE_RESULTS_SETTINGS_ENDPOINT = `/${API_VERSION}/mangools/settings`;

/**
 * Data source class handling communication with API for user data.
 */
class UserSource {
    static getData({ ssoTicket, loginToken, disableCache = null, settings = null }) {
        const query = queryString.stringify({
            r: Date.now(),
            sso_ticket: ssoTicket,
            login_token: loginToken,
            ...(!isNil(disableCache) && { disableCache }),
            ...(!isNil(settings) && { settings }),
        });
        const requestURL = `${APP_CONFIG.API_HOST}${USER_ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getLimitData(accessToken) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.KWFINDER_API_PATH}${LIMITS_ENDPOINT}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseAccountData(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static updateWhitelabelSettings({ accessToken, data }) {
        const requestURL = `${APP_CONFIG.API_HOST}${CURRENT_USER_ENDPOINT}/whitelabel`;

        const formData = new FormData();

        formData.set('logo', data.settings.logo);
        // eslint-disable-next-line no-param-reassign
        data.settings = omit(['logo'], data.settings);
        formData.set('data', JSON.stringify(data));

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken,
            },
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    return (
                        response
                            .json()
                            // eslint-disable-next-line no-shadow
                            .then(data => ({
                                error: false,
                                payload: parseSettings(data),
                            }))
                            .catch(ex => ({
                                error: true,
                                payload: {
                                    requestURL,
                                    status: ErrorCodes.PARSE_ERROR,
                                    text: ex.toString(),
                                },
                            }))
                    );
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static toggleWhitelabel({ accessToken, toggle = false }) {
        const requestURL = `${APP_CONFIG.API_HOST}${CURRENT_USER_ENDPOINT}/whitelabel/toggle`;

        return fetch(requestURL, {
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify({ toggle }),
        })
            .then(response => {
                if (response.ok) {
                    return {
                        error: false,
                        payload: null,
                    };
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static updateCustomizeResultsSettings({ accessToken, data }) {
        const requestURL = `${APP_CONFIG.API_HOST}${CUSTOMIZE_RESULTS_SETTINGS_ENDPOINT}`;

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken,
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.ok) {
                    return response.json().then(resData => ({
                        error: false,
                        payload: parseSettings(resData),
                    }))
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default UserSource;
