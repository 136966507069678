import update from 'immutability-helper';
import { isNil, uniq } from 'ramda';
import { REHYDRATE } from 'redux-persist/constants';
import { arrayMove } from '@dnd-kit/sortable';
import { ANYWHERE } from 'mangools-commons/dist/constants/Locations';
import { DESKTOP } from 'mangools-commons/dist/constants/Platforms';

import ActionTypes from 'constants/ActionTypes';
import SortingColumns from 'constants/SortingColumns';
import SortingTypes from 'constants/SortingTypes';

const initialState = {
    location: [ANYWHERE],
    platform: [DESKTOP],
    sortingSettings: {
        column: SortingColumns.ESTIMATED_VISITS_TOTAL,
        type: SortingTypes.DESC,
    },
    trackingGroupOrderedKeys: [],
};

const defaultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_DEFAULTS_LOCATION_SET: {
            return update(state, {
                location: { $set: action.payload },
            });
        }
        case ActionTypes.UI_DEFAULTS_PLATFORM_SET: {
            return update(state, {
                platform: { $set: action.payload },
            });
        }
        case ActionTypes.UI_DEFAULTS_SORTING_SETTINGS_SET: {
            return update(state, {
                sortingSettings: {
                    column: { $set: action.payload.column },
                    type: { $set: action.payload.type },
                },
            });
        }
        case ActionTypes.UI_DEFAULTS_TRACKING_GROUP_ORDERED_KEYS_SET: {
            const { trackingGroupKeys, oldIndex, newIndex } = action.payload;
            const sorted = uniq(arrayMove(trackingGroupKeys, oldIndex, newIndex));

            return update(state, {
                trackingGroupOrderedKeys: { $set: sorted },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.defaults)) {
                // We need to somehow support old data that might be stored in localstorage
                const defaultLocation =
                    savedState.defaults.location && Array.isArray(savedState.defaults.location)
                        ? savedState.defaults.location
                        : initialState.location;

                const defaultPlatform =
                    savedState.defaults.platform && Array.isArray(savedState.defaults.platform)
                        ? savedState.defaults.platform
                        : initialState.platform;
                return update(state, {
                    location: {
                        $set: defaultLocation,
                    },
                    platform: {
                        $set: defaultPlatform,
                    },
                    sortingSettings: {
                        $set: savedState.defaults.sortingSettings || initialState.sortingSettings,
                    },
                    trackingGroupOrderedKeys: {
                        $set:
                            uniq(savedState.defaults.trackingGroupOrderedKeys) || initialState.trackingGroupOrderedKeys,
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default defaultsReducer;
