import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { arrayOf, bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Urls from 'mangools-commons/dist/constants/Urls';

import MessageHolder from 'components/messages/MessageHolder';

class TrackingAlreadyExistsMessage extends Component {
    constructor() {
        super();
        this.handleAddKeywordsClick = this.handleAddKeywordsClick.bind(this);
    }

    shouldComponentUpdate(newProps, newState) {
        return shallowCompare(this, newProps, newState);
    }

    handleAddKeywordsClick() {
        this.props.onClose();
        this.props.onAddKeywords({
            trackingId: this.props.trackingId,
            keywords: this.props.keywords,
        });
    }

    render() {
        if (this.props.visible) {
            return (
                <MessageHolder onClose={this.props.onClose}>
                    <div className="mg-modal-header is-info">
                        <FontAwesomeIcon icon="exclamation" aria-label="Warning" />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-30 uk-text-bold">Tracking already exists</h3>
                        {this.props.keywords && (
                            <>
                                <p className="uk-margin-top">
                                    <strong>Change parameters</strong> if you want to create new tracking or just{' '}
                                    <strong>add keywords</strong> to the existing one.
                                </p>
                                <div className="mg-alert mg-margin-t-10 is-info font-14">
                                    <FontAwesomeIcon
                                        icon={['far', 'lightbulb']}
                                        className="mg-margin-r-10"
                                        aria-hidden="true"
                                    />
                                    If you need to categorize keywords, you can use the&nbsp;
                                    <a
                                        className="uk-text-bold"
                                        href={`${Urls.SERPWATCHER_GUIDE_URL}#tags`}
                                        rel="noopenner"
                                        target="_blank"
                                    >
                                        tags feature
                                    </a>
                                    .
                                </div>
                                <div className="mg-margin-t-30">
                                    <button
                                        className="mg-btn is-green is-small mg-margin-r-10"
                                        onClick={this.handleAddKeywordsClick}
                                        type="button"
                                    >
                                        Add to existing tracking
                                    </button>
                                    <button
                                        className="mg-btn is-link is-small"
                                        onClick={this.props.onClose}
                                        type="button"
                                    >
                                        Back
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </MessageHolder>
            );
        } else {
            return null;
        }
    }
}

TrackingAlreadyExistsMessage.propTypes = {
    keywords: arrayOf(string),
    onAddKeywords: func.isRequired,
    onClose: func.isRequired,
    trackingId: string,
    visible: bool.isRequired,
};

export default TrackingAlreadyExistsMessage;
