import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { MangoolsNavBar } from 'mangools-react-components/src';
import { LinkRefSources } from 'mangools-commons/dist/constants/LinkRefSources';
import DOMService from 'mangools-commons/dist/services/DOMService';

import mnglsImageWhite from 'images/mangools-logo-full-white.svg';

import {
    closeHelpDropdown,
    showAppPanel,
    showCustomizeReportPanel,
    showCustomizeResultsPanel,
    showHelpDropdown,
    showShortcutsMessage,
} from 'actions/uiActions';

import { requestedLogoutAction } from 'actions/userActions';

import { userPlanTypeSelector, isSubUserSelector, accessTokenSelector } from 'selectors/userSelectors';

import MenuLeft from 'components/layout/navbar/MenuLeft';
import MenuRight from 'components/layout/navbar/MenuRight';
import { DarkModeToggle } from 'mangools-react-components/src';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import { colorSchemeSelector } from 'selectors/uiSelectors';
import { trackingGroupsCountSelector, trackingsFetchedSelector } from 'selectors/dataSelectors';

function addJavascript(url) {
    return new Promise(function (resolve) {
        const tag = document.createElement('script');
        tag.src = url;
        tag.async = true;
        tag.onload = function () {
            resolve();
        };
        document.body.appendChild(tag);
    });
}

function addCss(url) {
    return new Promise(function (resolve) {
        const tag = document.createElement('link');
        tag.rel = 'stylesheet';
        tag.href = url;
        tag.onload = function () {
            resolve();
        };
        document.head.appendChild(tag);
    });
}

function NavbarContainer({ currentRoute }) {
    const dispatch = useDispatch();
    const {
        email,
        helpDropdownVisible,
        loggedIn,
        planType,
        trackedKeywordLimit,
        isSubUser,
        token,
        colorScheme,
        trackingGroupsCount,
        trackingsFetched,
    } = useSelector(state => ({
        email: state.user.email,
        helpDropdownVisible: state.ui.dropdowns.help.visibility,
        listCount: state.data.lists.count,
        loggedIn: state.user.loggedIn,
        planType: userPlanTypeSelector(state),
        trackedKeywordLimit: state.user.limits.trackedKeywordLimit,
        isSubUser: isSubUserSelector(state),
        token: accessTokenSelector(state),
        colorScheme: colorSchemeSelector(state),
        trackingGroupsCount: trackingGroupsCountSelector(state),
        trackingsFetched: trackingsFetchedSelector(state),
    }));

    // ... rest of the component ...

    const onCloseHelpDropdown = () => {
        dispatch(closeHelpDropdown());
    };
    const onShowHelpDropdown = () => {
        dispatch(showHelpDropdown());
    };

    const onShowShortcutsMessage = () => {
        dispatch(showShortcutsMessage());
    };

    const onShowAppPanel = () => {
        dispatch(showAppPanel());
    };

    const onShowCustomizeReportPanel = () => {
        dispatch(showCustomizeReportPanel());
    };

    const onShowCustomizeResultsPanel = () => {
        dispatch(showCustomizeResultsPanel());
    }

    const onLogoutClick = () => {
        dispatch(requestedLogoutAction());
    };

    const USER_DROPDOWN_VERSION_SRC = `https://mangools-micro-frontend.s3.amazonaws.com/user-dropdown/${process.env.USER_DROPDOWN_VERSION}`;
    const USER_DROPDOWN_VERSION_SRC_JS = `${USER_DROPDOWN_VERSION_SRC}/main.js`;
    const USER_DROPDOWN_VERSION_SRC_CSS = `${USER_DROPDOWN_VERSION_SRC}/main.css`;

    useEffect(() => {
        if (email) {
            addCss(USER_DROPDOWN_VERSION_SRC_CSS)
                .then(() => {
                    console.log('User dropdown css loaded');
                })
                .catch(error => {
                    console.error('User dropdown css loading error', error);
                });

            addJavascript(USER_DROPDOWN_VERSION_SRC_JS).then(() => {
                window.MangoolsUserDropdown.initReactApp()
                    .then(() => {
                        window.MangoolsUserDropdown.isInitialized
                            .then(() => {
                                window.MangoolsContext.setUser({
                                    email,
                                    isSubUser,
                                    plan: planType,
                                    token,
                                });
                                window.MangoolsContext.onLogout = () => {
                                    onLogoutClick();
                                };
                            })
                            .catch(error => {
                                console.error('User dropdown initialization error', error);
                            });
                    })
                    .catch(error => {
                        console.error('User dropdown initialization error', error);
                    });
            });
        }
    }, [colorScheme]);

    const mode = document.body.classList.contains('dark-mode') ? 'dark' : 'light';

    const renderUserImage = () => {
        if (loggedIn) {
            return (
                <div className="uk-flex uk-flex-middle">
                    {DOMService.isMobile() && (
                        <ul className="mg-nav uk-visible-small">
                            <li>
                                <DarkModeToggle />
                            </li>
                        </ul>
                    )}
                    <div className="mg-padding-0-15">
                        {email && (
                            <div
                                id="user-dropdown"
                                data-source={
                                    process.env.MANGOOLS_APP_HOST === 'https://mangools.com' ? 'production' : 'beta'
                                }
                                is-small="true"
                                data-theme-mode={mode}
                                data-tracking-ref="APP_SW"
                            />
                        )}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <header className="mg-header">
            <MangoolsNavBar
                appName={LinkRefSources.SERPWATCHER_APP}
                rightEl={renderUserImage()}
                onShowAppPanel={onShowAppPanel}
                logoDarkSrc={mnglsImageWhite}
                logoLightSrc={mnglsImageWhite}
                env={APP_CONFIG.APP_ENV}
                userPlanType={planType}
                mangoolsApiHost={APP_CONFIG.MANGOOLS_API_HOST}
            />
            <div className="mg-header-app uk-flex uk-flex-space-between mg-padding-0-15">
                <MenuLeft
                    currentRoute={currentRoute}
                    trackingGroupsCount={trackingGroupsCount}
                    trackingsFetched={trackingsFetched}
                    userPlanType={planType}
                    onShowCustomizeReportPanel={onShowCustomizeReportPanel}
                    onShowCustomizeResultsPanel={onShowCustomizeResultsPanel}
                    isSubUser={isSubUser}
                />
                <MenuRight
                    currentRoute={currentRoute}
                    helpDropdownVisible={helpDropdownVisible}
                    onCloseHelpDropdown={onCloseHelpDropdown}
                    onShowHelpDropdown={onShowHelpDropdown}
                    onShowShortcutsMessage={onShowShortcutsMessage}
                    trackedKeywordLimit={trackedKeywordLimit}
                />
            </div>
        </header>
    );
}

NavbarContainer.propTypes = {
    currentRoute: string,
};

export default NavbarContainer;
