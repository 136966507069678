import React, { PureComponent } from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MessageHolder from 'components/messages/MessageHolder';
import { formatEstimatedTime } from 'utils/formatEstimatedTime';

class AddedKeywordsMessage extends PureComponent {
    constructor() {
        super();

        this.renderHeader = this.renderHeader.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
        this.renderPostponedMessage = this.renderPostponedMessage.bind(this);
    }

    renderPostponedMessage() {
        if (this.props.postponedProcessing === true && this.props.keywords.length > 0) {
            return (
                <>
                    <div className="mg-alert is-warning font-14 mg-margin-t-10 mg-margin-b-15">
                        <h4 className="font-18 color-black">
                            <strong>Rank evaluation postponed</strong>
                        </h4>
                        <p className="uk-margin-top">
                            Your added keywords will have&nbsp;
                            <strong>rank evaluation postponed</strong>&nbsp; due to reaching your&nbsp;
                            <strong>
                                {this.props.keywordsLimit}/{this.props.keywordsLimit}
                            </strong>
                            &nbsp; rank evaluations per 24h limit.
                        </p>
                    </div>
                    <button className="mg-btn is-blue is-small" onClick={this.props.onClose} type="button">
                        Okay, got it
                    </button>
                </>
            );
        } else {
            return null;
        }
    }

    renderMessage() {
        if (this.props.postponedProcessing === false && this.props.keywords.length > 0) {
            return (
                <p>
                    Your ranks will be ready{' '}
                    <strong>in about {formatEstimatedTime(this.props.estimatedTimeProcessing)}</strong>. Please wait or
                    come back later.
                </p>
            );
        } else {
            return null;
        }
    }

    renderHeader() {
        const kwCount = this.props.keywords.length;
        let headerText = 'No keywords were added.';

        if (kwCount > 0) {
            headerText = `${kwCount} ${kwCount > 1 ? 'keywords were' : 'keyword was'} added`;
        }

        return <h3 className="font-30 uk-text-bold">{headerText}</h3>;
    }

    render() {
        if (this.props.visible) {
            return (
                <MessageHolder onClose={this.props.onClose}>
                    <div className="mg-modal-header is-success">
                        <FontAwesomeIcon icon="check" aria-label="Success" />
                    </div>
                    <div className="mg-modal-content">
                        {this.renderHeader()}
                        {this.renderMessage()}
                        {this.renderPostponedMessage()}
                        <div>
                            <button
                                className="mg-btn is-blue is-small"
                                onClick={this.props.onClose}
                                type="button"
                            >
                                OK, got it!
                            </button>
                        </div>
                    </div>
                </MessageHolder>
            );
        } else {
            return null;
        }
    }
}

AddedKeywordsMessage.propTypes = {
    estimatedTimeProcessing: number,
    keywords: arrayOf(string).isRequired,
    keywordsLimit: number.isRequired,
    onClose: func.isRequired,
    postponedProcessing: bool.isRequired,
    visible: bool.isRequired,
};

export default AddedKeywordsMessage;
