import RouterService from 'services/RouterService';
import { call, spawn, select, takeEvery } from 'redux-saga/effects';
import omit from 'ramda/src/omit';
import queryString from 'query-string';

import ActionTypes from 'constants/ActionTypes';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import { currentRouteSelector, currentQuerySelector } from 'selectors/commonSelectors';

import { navigatedInternallySelector } from 'selectors/uiSelectors';

function* hideAuthTokens() {
    const pathname = yield select(currentRouteSelector);
    const query = yield select(currentQuerySelector);
    const queryWithtoutAuthTokens = omit(['sso_ticket', 'login_token'], query);

    const queryString = new URLSearchParams(queryWithtoutAuthTokens).toString();
    const url = `${window.location.origin}${pathname}${queryString ? `?${queryString}` : ''}`;

    window.history.replaceState(null, '', url);
}

function* navigate(action) {
    const { route, params } = action.payload;
    yield call(RouterService.navigate, route, params);
}

function* replace(action) {
    const { route, params } = action.payload;
    yield call(RouterService.replace, route, params);
}

function* navigateBack() {
    const navigatedInternally = yield select(navigatedInternallySelector);

    if (navigatedInternally === true) {
        yield call(RouterService.navigateBack);
    } else {
        yield call(RouterService.navigateToRoot);
    }
}

function* watchHideAuthTokensRequests() {
    yield takeEvery(ActionTypes.ROUTER_AUTH_TOKENS_HIDE_REQUESTED, hideAuthTokens);
}

function* watchNavigationRequests() {
    yield takeEvery(ActionTypes.ROUTER_NAVIGATION_REQUESTED, navigate);
}

function* watchNavigationReplaceRequests() {
    yield takeEvery(ActionTypes.ROUTER_NAVIGATION_REPLACE_REQUESTED, replace);
}

function* watchNavigationBackRequests() {
    yield takeEvery(ActionTypes.ROUTER_NAVIGATION_BACK_REQUESTED, navigateBack);
}

export function* redirectToAuth() {
    const pathname = yield select(currentRouteSelector);
    const query = yield select(currentQuerySelector);

    const queryWithtoutAuthTokens = omit(['sso_ticket', 'login_token'], query);
    const url = `${APP_CONFIG.APPS_ROOT_HOST}${pathname}?${queryString.stringify(queryWithtoutAuthTokens)}`;

    yield call(RouterService.redirectToAuth, url);
}

export function* watchRouterRequests() {
    yield spawn(watchHideAuthTokensRequests);
    yield spawn(watchNavigationRequests);
    yield spawn(watchNavigationReplaceRequests);
    yield spawn(watchNavigationBackRequests);
}
