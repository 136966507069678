import { delay, call, spawn, put, race, select, takeLatest } from 'redux-saga/effects';
import { isNil } from 'ramda';
import {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/dist/constants/ErrorCodes';

import { handleUncaught, logError, handleError } from 'sagas/errorSagas';

import { accessTokenSelector } from 'selectors/userSelectors';
import { defaultLocationSelector } from 'selectors/defaultsSelectors';

import { showFailureMessage } from 'actions/uiActions';

import {
    fetchingSuggestedKwsResultsAction,
    receivedSuggestedKwsResultsAction,
    emptySuggestedKwsResultsAction,
    errorSuggestedKwsResultsAction,
    fetchingSuggestedKwsCountAction,
    receivedSuggestedKwsCountAction,
    errorSuggestedKwsCountAction,
} from 'actions/dataActions';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';
import { newTrackingDomainSelector } from 'selectors/uiSelectors';
import SuggestedKwsResultSource from 'sources/SuggestionsResultSource';
import SuggestedKwsCountSource from 'sources/SuggestionsCountSource';

const MAX_REQUEST_TIMEOUT = 60 * 1000

const fetchSuggestedKwsResults = handleUncaught(
    function* fetchSuggestedKwsResults(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const query = yield select(newTrackingDomainSelector);
        const location = yield select(defaultLocationSelector);

        const options = { accessToken, params: { query, locationId: location.id } };

        yield put(fetchingSuggestedKwsResultsAction());

        const { result, _timeout } = yield race({
            result: call(SuggestedKwsResultSource.getData, options),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedSuggestedKwsResultsAction(payload));

                const { keywords } = payload.data;

                const kw = keywords[0];

                if (isNil(kw)) {
                    yield put(emptySuggestedKwsResultsAction());
                }
            } else {
                const handler = handleError(
                    action,
                    payload,
                    'FetchSuggestedKwsResultsSaga',
                    retrying,
                    errorSuggestedKwsResultsAction,
                    null,
                    fetchSuggestedKwsResults,
                    Strings.messages.failure.fetch_suggestions_error,
                );

                yield call(handler);
            }
        } else {
            // Timeout
            yield put(errorSuggestedKwsResultsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_suggested_keywords_error }));
            yield call(logError, 'FetchSuggestedKwsResultsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSuggestedKwsResultsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_suggested_keywords_error }));
    },
);

const fetchSuggestedKwsCount = handleUncaught(
    function* fetchSuggestedKwsCount(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const location = yield select(defaultLocationSelector);
        const query = yield select(newTrackingDomainSelector);

        const options = { accessToken, params: { query, locationId: location[0].id } };

        yield put(fetchingSuggestedKwsCountAction());

        const { result, _timeout } = yield race({
            result: call(SuggestedKwsCountSource.getData, options),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedSuggestedKwsCountAction(payload));
            } else if (retrying === true) {
                yield put(errorSuggestedKwsCountAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchSuggestedKwsCountSaga', payload);
            } else {
                yield call(fetchSuggestedKwsCount, action, true);
            }
        } else {
            // Timeout
            yield put(errorSuggestedKwsCountAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchSuggestedKwsCountSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSuggestedKwsCountAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

function* watchSuggestedKwsResultsRequests() {
    yield takeLatest(ActionTypes.DATA_SUGGESTED_KWS_RESULTS_REQUESTED, fetchSuggestedKwsResults);
}

function* watchSuggestedKwsCountRequests() {
    yield takeLatest(ActionTypes.DATA_SUGGESTED_KWS_COUNT_REQUESTED, fetchSuggestedKwsCount);
}

export function* watchSuggestionsRequests() {
    yield spawn(watchSuggestedKwsResultsRequests);
    yield spawn(watchSuggestedKwsCountRequests);
}
