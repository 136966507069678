import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

interface QueryParams {
  [key: string]: string | string[] | undefined;
}

export const useQuery = (defaultValues: QueryParams = {}): QueryParams => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const result: QueryParams = { ...defaultValues };

    for (const [key, value] of params.entries()) {
      result[key] = value;
    }

    return result;
  }, [search, defaultValues]);
};
