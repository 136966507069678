import update, { extend } from 'immutability-helper';
import { includes, concat, uniq, omit } from 'ramda';
import Defaults from 'mangools-commons/dist/constants/Defaults';

import ActionTypes from 'constants/ActionTypes';

extend('$clearPatchedAction', (actionType, patches) => omit([actionType], patches));

const initialState = {
    data: {
        annotations: {
            data: [],
            errorDelete: {
                status: null,
                text: null,
            },
            errorCreate: {
                status: null,
                text: null,
            },
            errorUpdate: {
                status: null,
                text: null,
            },
            fetchingCreate: false,
        },
        createdAt: null,
        domain: null,
        id: null,
        keywords: [],
        location: {
            code: null,
            id: null,
            label: null,
        },
        platformId: null,
        shareToken: null,
        allStats: {
            timeframes: null,
        },
        selectedStats: {
            timeframes: null,
        },
        tags: {
            data: [],
            fetching: false,
        },
        timeframePoints: [],
        whiteLabel: {
            logo: null,
            accentColor: null,
            enabled: false,
            website: null,
            customDomain: null,
            name: null,
        },
        keywordsStatsMap: {},
    },
    error: {
        status: null,
        text: null,
    },
    errorUpdateDomain: {
        status: null,
        text: null,
    },
    errorTags: {
        status: null,
        text: null,
    },
    errorShareToken: {
        status: null,
        text: null,
    },
    fetchingStats: false,
    fetching: false,
    fetchingTimeframeData: false,
    fetchingShareToken: false,
    patches: {},
    trackingConfig: {
        place_id: null,
        forced_place_id: null,
        ludocid: null,
        name: null,
        address: null,
    },
};

const appendKwStatsData = kwsMap => item =>
    kwsMap[item.id]
        ? {
            ...item,
            performanceIndexChange: kwsMap[item.id]?.performanceIndexChange,
            estimatedVisitsChange: kwsMap[item.id]?.estimatedVisitsChange,
            rankChange: kwsMap[item.id]?.rankChange,
            rankHistory: kwsMap[item.id]?.rankHistory,
            rank: kwsMap[item.id]?.rank,
            rankAvg: kwsMap[item.id]?.rankAvg,
            rankBest: kwsMap[item.id]?.rankBest,
            estimatedVisits: kwsMap[item.id]?.estimatedVisits,
            estimatedVisitsTotal: kwsMap[item.id]?.estimatedVisitsTotal,
            searchVolume: kwsMap[item.id]?.searchVolume,
            lastCheckedAt: kwsMap[item.id]?.lastCheckedAt,
            mapPack: kwsMap[item.id]?.mapPack,
            featuredSnippet: kwsMap[item.id]?.featuredSnippet,
            isMoreUrl: kwsMap[item.id]?.isMoreUrl,
            estimatedVisitsTotalChange: kwsMap[item.id]?.estimatedVisitsTotalChange,
            visualMetrics: kwsMap[item.id]?.visualMetrics,
            isEmptyMainArray: kwsMap[item.id]?.isEmptyMainArray,
            nearestBeforeDay: kwsMap[item.id]?.nearestBeforeDay,
            serpFeatures: kwsMap[item.id]?.serpFeatures,
        }
        : item;

const trackingDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_TRACKING_DETAIL_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_STATS_DETAIL_FETCHING: {
            return update(state, {
                fetchingStats: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_TIMEFRAME_DATA_DETAIL_FETCHING: {
            return update(state, {
                fetchingTimeframeData: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_ALL_STATS_DETAIL_RECEIVED: {
            return update(state, {
                data: {
                    annotations: {
                        data: {
                            $set: action.payload.annotations,
                        },
                    },
                    allStats: {
                        timeframes: { $set: action.payload.stats?.timeframes },
                    },
                    selectedStats: {
                        timeframes: { $set: null },
                    },
                    keywordsStatsMap: { $set: action.payload.keywordsStatsMap },
                    timeframePoints: { $set: action.payload.timeframePoints },
                    keywords: { $set: state.data.keywords?.map(appendKwStatsData(action.payload.keywordsStatsMap)) },
                },
                fetchingStats: { $set: false },
                fetchingTimeframeData: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_SELECTED_STATS_DETAIL_RECEIVED: {
            return update(state, {
                data: {
                    annotations: {
                        data: {
                            $set: action.payload.annotations,
                        },
                    },
                    selectedStats: {
                        timeframes: { $set: action.payload.stats?.timeframes },
                    },
                    timeframePoints: { $set: action.payload.timeframePoints },
                    keywords: { $set: state.data.keywords?.map(appendKwStatsData(action.payload.keywordsStatsMap)) },
                },
                fetchingStats: { $set: false },
                fetchingTimeframeData: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_DETAIL_RECEIVED: {
            return update(state, {
                data: {
                    createdAt: { $set: action.payload.createdAt },
                    domain: { $set: action.payload.domain },
                    id: { $set: action.payload.id },
                    keywords: { $set: action.payload.keywords.map(appendKwStatsData(state.data.keywordsStatsMap)) },
                    location: { $set: action.payload.location },
                    trackingConfig: { $set: action.payload.trackingConfig },
                    platformId: { $set: action.payload.platformId },
                    shareToken: { $set: action.payload.shareToken },
                    whiteLabel: { $set: action.payload.whiteLabel },
                },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_PANEL_RECEIVED: {
            return update(state, {
                data: {
                    id: { $set: action.payload.id },
                    domain: { $set: action.payload.domain },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_RECEIVED: {
            return update(state, {
                data: {
                    annotations: {
                        data: {
                            $apply: annotations => concat(annotations, [action.payload]),
                        },
                        errorCreate: {
                            $set: initialState.data.annotations.errorCreate,
                        },
                        fetchingCreate: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_ERROR: {
            return update(state, {
                data: {
                    annotations: {
                        errorCreate: {
                            status: { $set: action.payload.status },
                            text: { $set: action.payload.text },
                        },
                        fetchingCreate: {
                            $set: false,
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_FETCHING: {
            return update(state, {
                data: {
                    annotations: {
                        fetchingCreate: {
                            $set: true,
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_RECEIVED: {
            return update(state, {
                data: {
                    annotations: {
                        data: {
                            $apply: annotations => annotations.filter(annotation => annotation.id !== action.payload),
                        },
                        errorDelete: {
                            $set: initialState.data.annotations.errorDelete,
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_ERROR: {
            return update(state, {
                data: {
                    annotations: {
                        errorDelete: {
                            status: { $set: action.payload.status },
                            text: { $set: action.payload.text },
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_RECEIVED: {
            return update(state, {
                data: {
                    annotations: {
                        data: {
                            $apply: annotations =>
                                annotations.map(annotation => {
                                    if (annotation.id === action.payload.annotationId) {
                                        return update(annotation, {
                                            content: {
                                                text: {
                                                    $set: action.payload.annotationText,
                                                },
                                            },
                                            nonShareable: {
                                                $set: action.payload.nonShareable,
                                            },
                                        });
                                    } else {
                                        return annotation;
                                    }
                                }),
                        },
                        errorUpdate: {
                            $set: initialState.data.annotations.errorCreate,
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_ERROR: {
            return update(state, {
                data: {
                    annotations: {
                        errorUpdate: {
                            status: { $set: action.payload.status },
                            text: { $set: action.payload.text },
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED: {
            return update(state, {
                data: {
                    keywords: {
                        $apply: kws => kws.filter(kw => !includes(kw.id, action.payload.keywordIds)),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_RECEIVED: {
            return update(state, {
                data: {
                    keywords: {
                        $apply: kws => kws.filter(kw => kw.id !== action.payload.keywordId),
                    },
                },
            });
        }
        case ActionTypes.UI_TRACKING_RESET: {
            return update(state, {
                data: {
                    allStats: {
                        timeframes: { $set: null },
                    },
                    selectedStats: {
                        timeframes: { $set: null },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_ERROR:
        case ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_ERROR:
        case ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_ERROR:
        case ActionTypes.DATA_TRACKING_DETAIL_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetching: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_ADD_KEYWORDS_RECEIVED: {
            const { data, assignedTagIds, trackingTags, updatedOldKeywordStrings } = action.payload;
            const allKeywords = data.keywords;

            const currentKeywordsIds = state.data.keywords.map(kw => kw.id);
            const onlyNewKeywords = allKeywords.filter(kw => !includes(kw.id, currentKeywordsIds));
            const addedTagsKeywords = allKeywords.filter(kw => includes(kw.keyword, updatedOldKeywordStrings));
            const addedTagsKeywordIds = addedTagsKeywords.map(kw => kw.id);
            const tags = trackingTags.filter(tag => assignedTagIds.includes(tag.id));

            // Construct keyword object with default NOT_AVAILABLE values
            const newKeywordObjects = onlyNewKeywords.map(kw => ({
                createdAt: parseInt(Date.now() / 1000, 10),
                estimatedVisits: Defaults.NO_VALUE,
                id: kw.id,
                keyword: kw.keyword,
                lastCheckedAt: 0,
                rank: Defaults.NOT_AVAILABLE,
                rankAvg: Defaults.NOT_AVAILABLE,
                rankBest: Defaults.NOT_AVAILABLE,
                rankChange: Defaults.NOT_AVAILABLE,
                rankHistory: [Defaults.NOT_AVAILABLE, Defaults.NOT_AVAILABLE, Defaults.NOT_AVAILABLE],
                searchVolume: Defaults.NO_VALUE,
                tags,
                url: Defaults.NOT_AVAILABLE,
            }));

            return update(state, {
                data: {
                    keywords: {
                        $apply: kws =>
                            concat(
                                kws.map(kw => {
                                    if (includes(kw.id, addedTagsKeywordIds)) {
                                        return update(kw, {
                                            tags: {
                                                $apply: kwTags => {
                                                    const currentTagIds = kwTags.map(tag => tag.id);
                                                    const newTagIds = uniq(concat(currentTagIds, assignedTagIds));

                                                    if (currentTagIds.length !== newTagIds.length) {
                                                        return trackingTags.filter(tag => includes(tag.id, newTagIds));
                                                    } else {
                                                        return kwTags;
                                                    }
                                                },
                                            },
                                        });
                                    } else {
                                        return kw;
                                    }
                                }),
                                newKeywordObjects,
                            ),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_FETCHING: {
            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: true },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_RECEIVED: {
            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                        data: { $set: action.payload },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_ERROR: {
            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                        data: { $set: initialState.data.tags.data },
                    },
                },
                errorTags: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_RECEIVED: {
            const allTags = action.payload.trackingTags;
            const { tagIds } = action.payload;
            const { keywordIds } = action.payload;

            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                    },
                    keywords: {
                        $apply: kws =>
                            kws.map(kw => {
                                if (includes(kw.id, keywordIds)) {
                                    return update(kw, {
                                        tags: {
                                            $apply: tags => {
                                                const currentTagIds = tags.map(tag => tag.id);
                                                const newTagIds = uniq(concat(currentTagIds, tagIds));

                                                if (currentTagIds.length !== newTagIds.length) {
                                                    return allTags.filter(tag => includes(tag.id, newTagIds));
                                                } else {
                                                    return tags;
                                                }
                                            },
                                        },
                                    });
                                } else {
                                    return kw;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_RECEIVED: {
            const { tagIds } = action.payload;
            const { keywordIds } = action.payload;

            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                    },
                    keywords: {
                        $apply: kws =>
                            kws.map(kw => {
                                if (includes(kw.id, keywordIds)) {
                                    return update(kw, {
                                        tags: {
                                            $apply: tags => tags.filter(tag => !includes(tag.id, tagIds)),
                                        },
                                    });
                                } else {
                                    return kw;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_ASSIGN_RECEIVED: {
            const allTags = action.payload.trackingTags;
            const { tagIds } = action.payload;
            const { keywordId } = action.payload;

            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                    },
                    keywords: {
                        $apply: kws =>
                            kws.map(kw => {
                                if (kw.id === keywordId) {
                                    return update(kw, {
                                        tags: {
                                            $apply: tags => {
                                                const currentTagIds = tags.map(tag => tag.id);
                                                const newTagIds = uniq(concat(currentTagIds, tagIds));

                                                if (currentTagIds.length !== newTagIds.length) {
                                                    return allTags.filter(tag => includes(tag.id, newTagIds));
                                                } else {
                                                    return tags;
                                                }
                                            },
                                        },
                                    });
                                } else {
                                    return kw;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_RECEIVED: {
            const { tagId } = action.payload;
            const { keywordId } = action.payload;

            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                    },
                    keywords: {
                        $apply: kws =>
                            kws.map(kw => {
                                if (kw.id === keywordId) {
                                    return update(kw, {
                                        tags: {
                                            $apply: tags => tags.filter(tag => tag.id !== tagId),
                                        },
                                    });
                                } else {
                                    return kw;
                                }
                            }),
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_CREATE_RECEIVED: {
            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                        data: { $apply: tags => concat([action.payload.tag], tags) },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_RECEIVED: {
            return update(state, {
                data: {
                    keywords: {
                        $apply: kws =>
                            kws.map(kw =>
                                update(kw, {
                                    tags: {
                                        $apply: tags =>
                                            tags.map(tag => {
                                                if (tag.id === action.payload.tagId) {
                                                    return update(tag, {
                                                        name: {
                                                            $set: action.payload.tagName,
                                                        },
                                                    });
                                                } else {
                                                    return tag;
                                                }
                                            }),
                                    },
                                }),
                            ),
                    },
                    tags: {
                        fetching: { $set: false },
                        data: {
                            $apply: tags =>
                                tags.map(tag => {
                                    if (tag.id === action.payload.tagId) {
                                        return update(tag, {
                                            name: {
                                                $set: action.payload.tagName,
                                            },
                                        });
                                    } else {
                                        return tag;
                                    }
                                }),
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_RECEIVED: {
            return update(state, {
                data: {
                    keywords: {
                        $apply: kws =>
                            kws.map(kw =>
                                update(kw, {
                                    tags: {
                                        $apply: tags =>
                                            tags.map(tag => {
                                                if (tag.id === action.payload.tagId) {
                                                    return update(tag, {
                                                        color: {
                                                            $set: action.payload.tagColor,
                                                        },
                                                    });
                                                } else {
                                                    return tag;
                                                }
                                            }),
                                    },
                                }),
                            ),
                    },
                    tags: {
                        fetching: { $set: false },
                        data: {
                            $apply: tags =>
                                tags.map(tag => {
                                    if (tag.id === action.payload.tagId) {
                                        return update(tag, {
                                            color: {
                                                $set: action.payload.tagColor,
                                            },
                                        });
                                    } else {
                                        return tag;
                                    }
                                }),
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_DELETE_RECEIVED: {
            return update(state, {
                data: {
                    keywords: {
                        $apply: kws =>
                            kws.map(kw =>
                                update(kw, {
                                    tags: {
                                        $apply: tags => tags.filter(tag => tag.id !== action.payload),
                                    },
                                }),
                            ),
                    },
                    tags: {
                        fetching: { $set: false },
                        data: {
                            $apply: tags => tags.filter(tag => tag.id !== action.payload),
                        },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE: {
            return update(state, {
                data: { $merge: action.payload },
                patches: {
                    $merge: {
                        [ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT]: { domain: state.data.domain },
                    },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT: {
            return update(state, {
                data: { $merge: state.patches[action.type] },
                patches: { $clearPatchedAction: action.type },
            });
        }
        case ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_RECEIVED: {
            return update(state, {
                data: { $merge: action.payload },
                errorUpdateDomain: {
                    status: { $set: null },
                    text: { $set: null },
                },
                patches: { $clearPatchedAction: ActionTypes.DATA_TRACKING_DOMAIN_OPTIMISTIC_UPDATE_REVERT },
            });
        }
        case ActionTypes.DATA_TRACKING_UPDATE_DOMAIN_ERROR: {
            return update(state, {
                errorUpdateDomain: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_REQUESTED: {
            return update(state, {
                fetchingShareToken: { $set: true },
            });
        }
        case ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_RECEIVED: {
            return update(state, {
                data: {
                    shareToken: { $set: action.payload },
                },
                errorShareToken: {
                    status: { $set: null },
                    text: { $set: null },
                },
                fetchingShareToken: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_ERROR: {
            return update(state, {
                errorShareToken: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
                fetchingShareToken: { $set: false },
            });
        }
        case ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_ERROR:
        case ActionTypes.DATA_TRACKING_TAGS_CREATE_ERROR:
        case ActionTypes.DATA_TRACKING_TAGS_ASSIGN_ERROR:
        case ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_ERROR:
        case ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_ERROR:
        case ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_ERROR: {
            return update(state, {
                data: {
                    tags: {
                        fetching: { $set: false },
                    },
                },
                errorTags: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default trackingDetailReducer;
