import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import RouterService from 'services/RouterService';
import RoutePaths from 'constants/RoutePaths';

import { NewTrackingStep } from 'components/newTracking/step/NewTrackingStep';
import { useDispatch, useSelector } from 'react-redux';
import { resetNewTracking } from 'actions/uiActions';
import { requestedNavigationAction } from 'actions/routerActions';
import { useQuery } from 'mangools-react-components/src';

const MenuLeft = ({ currentRoute, onShowCustomizeReportPanel, trackingGroupsCount, isSubUser, onShowCustomizeResultsPanel }: MenuLeftProps) => {
    const dispatch = useDispatch();
    const { step: newTrackingStep } = useQuery({
        step: NewTrackingStep.Domain
    })

    const TrackingLink = (
        <Link className="menu-link" to={RoutePaths.TRACKINGS}>
            <FontAwesomeIcon
                icon="list"
                className="mg-margin-r-10 uk-hidden-large uk-hidden-medium"
                aria-hidden="true"
            />
            Trackings
            <sup className="mg-margin-l-5 font-10 uk-hidden-small">{trackingGroupsCount}</sup>
        </Link>
    );

    const handleNewTrackingNav = () => {
        if (RouterService.isSame(currentRoute, RoutePaths.NEW)) {
            if (newTrackingStep === NewTrackingStep.Keywords) {
                const isConfirmed = window.confirm('You have unsaved changes. Are you sure you want to continue?');

                if (isConfirmed) {
                    dispatch(resetNewTracking());
                } else {
                    return;
                }
            } else if(newTrackingStep === NewTrackingStep.Complete) {
                dispatch(resetNewTracking());
            }
        }

        dispatch(requestedNavigationAction(RoutePaths.NEW));
    }

    return (
        <nav className='uk-flex uk-flex-middle'>
            <ul className="mg-nav is-app uk-flex-inline uk-padding-remove">
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(currentRoute, RoutePaths.TRACKINGS),
                    })}
                >
                    {TrackingLink}
                </li>
                <li
                    className={classnames({
                        'uk-active': RouterService.isSame(currentRoute, RoutePaths.NEW),
                    })}
                >
                    <a className="menu-link" onClick={handleNewTrackingNav}>
                        <FontAwesomeIcon
                            icon="plus-square"
                            className="mg-margin-r-10 uk-hidden-large uk-hidden-medium"
                            aria-hidden="true"
                        />
                        New
                        <span className="uk-hidden-small">&nbsp; Tracking</span>
                    </a>
                </li>
                {!isSubUser && (
                    <li className="uk-hidden-small uk-position-relative">
                        <button onClick={onShowCustomizeReportPanel} type="button">
                            Whitelabel
                        </button>
                    </li>
                )}
                <li className="uk-position-relative">
                    <a className="menu-link" onClick={onShowCustomizeResultsPanel}>
                        <FontAwesomeIcon
                            icon="cog"
                            className="mg-margin-r-10 uk-hidden-large uk-hidden-medium"
                            aria-hidden="true"
                        />
                        Customization
                    </a>
                </li>
            </ul>
        </nav>
    );
    /* eslint-enable max-len */
}

interface MenuLeftProps {
    currentRoute: string;
    trackingGroupsCount: number;
    onShowCustomizeReportPanel: () => void;
    onShowCustomizeResultsPanel: () => void;
    isSubUser: boolean;
}

export default MenuLeft;
