import React, { PureComponent } from 'react';
import { bool, func, number, string } from 'prop-types';
import isNil from 'ramda/src/isNil';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';

import { MOBILE } from 'mangools-commons/dist/constants/Platforms';
import Urls from 'mangools-commons/dist/constants/Urls';

import serpcheckerIcon from 'images/serpchecker-icon.svg';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

const SERP_MODAL_CONTAINER_ID = 'SERP_MODAL_CONTAINER_ID';

class SerpModal extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            iframeLoading: true,
        };

        this.iframeRef = null;
        this.isMobile = this.isMobile.bind(this);
        this.handleModalClick = this.handleModalClick.bind(this);
        this.handleOpenInSerpchecker = this.handleOpenInSerpchecker.bind(this);
        this.onIframeLoaded = this.onIframeLoaded.bind(this);
        this.renderLoader = this.renderLoader.bind(this);
        this.handleSaveImage = this.handleSaveImage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.visible === false && this.props.visible === true) {
            this.iframeRef.addEventListener('load', this.onIframeLoaded);
        }
    }

    componentWillUnmount() {
        if (!isNil(this.iframeRef)) {
            this.iframeRef.removeEventListener('load', this.onIframeLoaded);
        }
    }

    onIframeLoaded() {
        this.setState({
            iframeLoading: false,
        });
    }

    isMobile() {
        return this.props.platformId === MOBILE.id;
    }

    handleOpenInSerpchecker(e) {
        e.preventDefault();

        const query = queryString.stringify({
            keyword: this.props.keyword,
            location: this.props.locationId,
            platform: this.props.platformId,
            ref: 'serp-modal-app-sw',
        });

        const url = `${Urls.SERPCHECKER_APP_URL}/dashboard?${query}`;
        window.open(url, '_blank');
    }

    handleModalClick(e) {
        if (e.target.id === SERP_MODAL_CONTAINER_ID) {
            e.stopPropagation();
            this.props.onClose();
        }
    }

    handleSaveImage() {
        this.props.requestSnapshotImage();
    }

    /* eslint-disable max-len */
    renderLoader() {
        if (this.state.iframeLoading) {
            if (this.isMobile()) {
                return (
                    <div
                        className="uk-position-top-center uk-positon-z-index bg-fff"
                        style={{ height: '540px', width: '326px', overflow: 'hidden', top: '55px' }}
                    >
                        <div className="bg-grey mg-padding-15">
                            <div className="bg-fff uk-width-1-1" style={{ height: '30px' }} />
                        </div>
                        <div className="mg-border-b mg-padding-15  bg-fff">
                            <div className="uk-grid">
                                <div className="uk-width-2-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-2-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-2-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="ln-11 bg-fff mg-padding-15">
                            <div className="mg-margin-b-30">
                                <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                            </div>
                            <div className="mg-margin-b-30">
                                <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                            </div>
                            <div className="mg-margin-b-30">
                                <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        className="uk-position-cover uk-position-z-index mg-card"
                        style={{ height: '90vh', overflow: 'hidden' }}
                    >
                        <div className="bg-dark" style={{ height: '28px' }} />
                        <div className="bg-grey mg-padding-30-0">
                            <div className="bg-fff" style={{ height: '30px', marginLeft: '132px', width: '555px' }} />
                        </div>
                        <div className="mg-border-b mg-padding-15-0">
                            <div className="uk-grid" style={{ width: '900px', marginLeft: '97px' }}>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                                <div className="uk-width-1-10">
                                    <div className="mg-preloader-block uk-width-1-1" style={{ height: '14px' }} />
                                </div>
                            </div>
                        </div>
                        <div className="uk-flex mg-margin-t-30 ln-11">
                            <div style={{ width: '700px', marginLeft: '132px' }}>
                                <div className="mg-margin-b-30">
                                    <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                    <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                    <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                                </div>
                                <div className="mg-margin-b-30">
                                    <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                    <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                    <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                                </div>
                                <div className="mg-margin-b-30">
                                    <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                    <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                    <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                                </div>
                                <div className="mg-margin-b-30">
                                    <div className="mg-preloader-block uk-width-2-3" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-3-4" style={{ height: '16px' }} />
                                    <div className="mg-preloader-block uk-width-8-10" style={{ height: '12px' }} />
                                    <div className="mg-preloader-block uk-width-6-10" style={{ height: '10px' }} />
                                    <div className="mg-preloader-block uk-width-7-10" style={{ height: '10px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return null;
        }
    }
    /* eslint-enable max-len */

    render() {
        if (this.props.visible) {
            const IFRAME_SRC = isNil(this.props.serpSnapshotId)
                ? ''
                : `${APP_CONFIG.production() ? Urls.SNAPSHOT_BASE_URL : Urls.SNAPSHOT_BETA_BASE_URL}/${this.props.serpSnapshotId}.html`;

            const iframeClasses = classnames({
                'snapshot-frame': true,
                'is-mobile': this.isMobile(),
            });

            const modalClasses = classnames('uk-modal', 'uk-display-block', {
                'uk-open': this.props.visible,
                'is-mobile': this.isMobile(),
            });

            const modalContenClasses = classnames('mg-modal-content', 'uk-padding-remove', {
                'bg-dark': this.isMobile() === false,
            });

            return (
                <div id={SERP_MODAL_CONTAINER_ID} className={modalClasses} onClick={this.handleModalClick}>
                    <div className="uk-modal-dialog is-large uk-padding-remove">
                        <button
                            aria-label="Close"
                            className="mg-close"
                            onClick={this.props.onClose}
                            style={{ zIndex: '2' }}
                            type="button"
                        >
                            <FontAwesomeIcon icon="times" aria-hidden="true" />
                        </button>
                        <div className={modalContenClasses} style={{ maxHeight: 'unset' }}>
                            <div className="uk-position-relative uk-flex uk-flex-center">
                                <iframe
                                    className={iframeClasses}
                                    ref={c => (this.iframeRef = c)}
                                    src={IFRAME_SRC}
                                    title={`SERP view for ${this.props.keyword}`}
                                />
                                {this.renderLoader()}
                            </div>
                        </div>
                        <div className="mg-modal-footer uk-text-center">
                            {!IS_CUSTOM_DOMAIN ? (
                                <button
                                    className="mg-btn is-white is-xsmall mg-margin-r-10"
                                    onClick={this.handleOpenInSerpchecker}
                                    type="button"
                                >
                                    <img
                                        alt="Sch"
                                        className="mg-margin-r-5"
                                        src={serpcheckerIcon}
                                        style={{ marginTop: '-2px' }}
                                        width="9"
                                    />
                                    Open in SERPChecker
                                </button>
                            ) : null}
                            <button
                                className="mg-btn is-blue is-xsmall"
                                disabled={this.props.serpSnapshotId.length === 0 || this.props.fetchingSnapshot}
                                onClick={this.handleSaveImage}
                                type="button"
                            >
                                <FontAwesomeIcon icon="download" aria-hidden="true" />
                                {this.props.fetchingSnapshot ? 'Fetching...' : 'Download SERP'}
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

SerpModal.propTypes = {
    keyword: string.isRequired,
    locationId: number,
    onClose: func.isRequired,
    platformId: number,
    visible: bool.isRequired,
    requestSnapshotImage: func.isRequired,
    serpSnapshotId: string.isRequired,
    fetchingSnapshot: bool.isRequired,
};

export default SerpModal;
