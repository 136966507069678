import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import FullAppPreloader from 'components/layout/FullAppPreloader';
import IndexContainer from 'components/index/IndexContainer';
import LayoutContainer from 'components/layout/LayoutContainer';

import RoutePaths from 'constants/RoutePaths';
import AsyncLoaderErrorBoundaryContainer
    from 'mangools-react-components/src/components/AsyncLoaderErrorBoundaryContainer';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';
import { RouterEventListener } from 'mangools-react-components/src';
import ActionTypes from '../constants/ActionTypes';

const NewTrackingContainer = lazy(() => import('./newTracking/NewTrackingContainer'));
const TrackingContainer = lazy(() => import('components/tracking/TrackingContainer'));
const TrackingListContainer = lazy(() => import('components/trackingList/TrackingListContainer'));
const NotFound = lazy(() => import('components/other/NotFound'));
const CustomDomainMissingTrackingId = lazy(() => import('components/other/CustomDomainMissingTrackingId'));

const withAsyncLoader = (Component) => (props) => (
    <AsyncLoaderErrorBoundaryContainer>
        <Suspense fallback={<FullAppPreloader/>}>
            <Component {...props} />
        </Suspense>
    </AsyncLoaderErrorBoundaryContainer>
);

const LoadNewTrackingContainer = withAsyncLoader(NewTrackingContainer);
const LoadTrackingContainer = withAsyncLoader(TrackingContainer);
const LoadTrackingListContainer = withAsyncLoader(TrackingListContainer);
const LoadNotFound = withAsyncLoader(NotFound);

const MissingTrackingId = (props) => {
    return (
        <AsyncLoaderErrorBoundaryContainer>
            <Suspense fallback={<FullAppPreloader />}>
                <CustomDomainMissingTrackingId {...props} />
            </Suspense>
        </AsyncLoaderErrorBoundaryContainer>
    );
}

const fullAppRouter = (
    <Route path={RoutePaths.ROOT} element={<LayoutContainer/>}>
        <Route index element={<IndexContainer/>}/>
        <Route path={RoutePaths.NEW} element={<LoadNewTrackingContainer/>}/>
        <Route path={RoutePaths.TRACKINGS} element={<LoadTrackingListContainer/>}/>
        <Route path={RoutePaths.TRACKING} element={<LoadTrackingContainer/>}/>
        <Route path={RoutePaths.REPORT} element={<LoadTrackingContainer/>}/>
        <Route path="*" element={<LoadNotFound/>}/>
    </Route>
);

const customDomainRouter = (
    <Route path={RoutePaths.ROOT} element={<LayoutContainer/>}>
        <Route index element={<MissingTrackingId />}/>
        <Route path={RoutePaths.REPORT} element={<LoadTrackingContainer/>}/>
        <Route path="*" element={<LoadNotFound/>}/>
    </Route>
);

const getRouter = () => (IS_CUSTOM_DOMAIN ? customDomainRouter : fullAppRouter);

export const Router = () => (
    <BrowserRouter>
        <RouterEventListener actionOnLocationChange={ActionTypes.ROUTER_LOCATION_CHANGE}/>
        <Routes>
            {getRouter()}
        </Routes>
    </BrowserRouter>
);
