import React, { PureComponent } from 'react';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DESKTOP, MOBILE } from 'mangools-commons/dist/constants/Platforms';
import MessageHolder from 'components/messages/MessageHolder';

class CreateTrackingMessage extends PureComponent {
    constructor() {
        super();
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.renderCreateButtonBody = this.renderCreateButtonBody.bind(this);
        this.renderMessage = this.renderMessage.bind(this);
    }

    handleCreateClick() {
        this.props.onCreate(this.props.templateTrackingId);
    }

    renderMessage() {
        if (this.props.platformLabel === DESKTOP.label) {
            return (
                <div className="mg-alert mg-margin-t-10 is-info font-14">
                    <FontAwesomeIcon icon={['far', 'lightbulb']} className="mg-margin-r-10" aria-hidden="true" />
                    Desktop and mobile positions <strong>may vary significantly</strong> for some keywords&nbsp; and
                    desktop search is <strong>still very relevant traffic</strong> source for many websites.
                </div>
            );
        } else if (this.props.platformLabel === MOBILE.label) {
            return (
                <div className="mg-alert mg-margin-t-10 is-info font-14">
                    <FontAwesomeIcon icon={['far', 'lightbulb']} className="mg-margin-r-10" aria-hidden="true" />
                    Desktop and mobile positions <strong>may vary significantly</strong> for some keywords&nbsp; and
                    mobile search is quickly <strong>becoming the main traffic source</strong> for many websites.
                </div>
            );
        } else {
            return null;
        }
    }

    renderCreateButtonBody() {
        if (this.props.fetching) {
            return (
                <span className="mg-preloader-inline">
                    <span />
                    <span />
                    <span />
                </span>
            );
        } else {
            return <span>Yes, clone tracking</span>;
        }
    }

    render() {
        if (this.props.visible) {
            return (
                <MessageHolder onClose={this.props.onClose}>
                    <div className="mg-modal-header is-info uk-flex uk-flex-middle uk-flex-center">
                        <FontAwesomeIcon icon={['far', 'clone']} />
                        <span className="font-18 mg-padding-0-15">
                            <FontAwesomeIcon icon="chevron-right" />
                        </span>
                        <FontAwesomeIcon icon={this.props.platformLabel === DESKTOP.label ? 'laptop' : 'mobile-alt'} />
                    </div>
                    <div className="mg-modal-content">
                        <h3 className="font-30 uk-text-bold">
                            Enable {this.props.platformLabel.toLowerCase()} tracking
                        </h3>
                        <p>
                            Do you want to clone <strong>{this.props.domain}</strong> tracking for&nbsp;
                            <strong>{this.props.platformLabel.toLowerCase()}</strong> devices?
                        </p>
                        {this.renderMessage()}
                        <div className="mg-margin-t-15">
                            <button
                                className="mg-btn is-green is-small"
                                disabled={this.props.fetching}
                                onClick={this.handleCreateClick}
                                type="button"
                                style={{ minWidth: '153px' }}
                            >
                                {this.renderCreateButtonBody()}
                            </button>
                            <button
                                className="mg-btn is-link is-small mg-margin-l-10"
                                disabled={this.props.fetching}
                                onClick={this.props.onClose}
                                type="button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </MessageHolder>
            );
        } else {
            return null;
        }
    }
}

CreateTrackingMessage.propTypes = {
    domain: string,
    fetching: bool.isRequired,
    onClose: func.isRequired,
    onCreate: func.isRequired,
    platformLabel: string,
    templateTrackingId: string,
    visible: bool.isRequired,
};

export default CreateTrackingMessage;
