import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';
import { GlobalHotKeys } from 'react-hotkeys';
import ShortcutService from 'mangools-commons/dist/services/ShortcutService';
import { Alert } from 'mangools-react-components/src';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll, resetTrackingKeywordDetail, showShortcutsMessage, closeShortcutsMessage } from 'actions/uiActions';

import RouterService from 'services/RouterService';

import RoutePaths from 'constants/RoutePaths';
import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    shortcutsMessageVisibilitySelector,
    trackingCurrentKeywordDetailSelector,
    areShortcutsDisabledSelector,
} from 'selectors/uiSelectors';

import {
    announcementMesageVisibilitySelector,
    isTrackingDetailWhitelabelLogoActiveSelector,
} from 'selectors/dataSelectors';

import { newUserDiscountActiveSelector } from 'selectors/userSelectors';
import { IS_CUSTOM_DOMAIN } from 'constants/isCustomDomain';

const LayoutContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const currentTrackingKeywordDetailId = useSelector(trackingCurrentKeywordDetailSelector);
    const mobileOverlayVisibility = useSelector(mobileOverlayVisibilitySelector);
    const onlineStatus = useSelector(onlineStatusSelector);
    const overlayVisibility = useSelector(overlayVisibilitySelector);
    const shortcutsMessageVisible = useSelector(shortcutsMessageVisibilitySelector);
    const topLevelMessageVisible = useSelector(
        (state) =>
            newUserDiscountActiveSelector(state) || announcementMesageVisibilitySelector(state)
    );
    const areShortcutsDisabled = useSelector(areShortcutsDisabledSelector);
    const isWhiteLabelLogoActive = useSelector(isTrackingDetailWhitelabelLogoActiveSelector);

    const handleToggleShortcuts = () => {
        if (shortcutsMessageVisible) {
            dispatch(closeShortcutsMessage());
        } else {
            dispatch(showShortcutsMessage());
        }
    };

    const isReport = () => RouterService.isSame(location.pathname, RoutePaths.REPORT);

    const appClasses = classnames({
        'is-offline': onlineStatus === false,
        'is-report': isReport(),
        'is-report-with-logo': isReport() && isWhiteLabelLogoActive,
        'serpwatcher-app': true,
        'is-messaged': topLevelMessageVisible,
    });

    const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
        handlers: {
            [CLOSE_ALL]: { func: () => dispatch(closeAll()), preventDisable: true },
            [SHOW_SHORTCUTS_TOGGLE]: { func: handleToggleShortcuts },
        },
        areShortcutsDisabled,
        shortcuts,
    });

    return (
        <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}>
            <div className={appClasses}>
                <TopLevelMessageContainer />
                {IS_CUSTOM_DOMAIN === false && <NavbarContainer currentRoute={location.pathname} />}
                <Outlet />
                <PanelContainer />
                <ModalContainer />
                <MessageContainer currentRoute={location.pathname} />
                <Overlay mobile={false} onClick={() => dispatch(closeAll())} visibility={overlayVisibility} />
                <Overlay mobile onClick={() => dispatch(closeAll())} visibility={mobileOverlayVisibility} />
                <Overlay
                    mobile
                    onClick={() => dispatch(resetTrackingKeywordDetail())}
                    visibility={!isNil(currentTrackingKeywordDetailId)}
                />
                <div className="uk-hidden-small">
                    <Alert />
                </div>
            </div>
        </GlobalHotKeys>
    );
};

export default LayoutContainer;
