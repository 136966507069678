import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RoutePaths from 'constants/RoutePaths';
import Preloader from 'components/other/Preloader';
import { requestedNavigationReplaceAction } from 'actions/routerActions';
import { firstTrackingIdSelector, trackingCountSelector, trackingsFetchedSelector } from 'selectors/dataSelectors';
import { fetchedSelector, limitsFetchedSelector, loggedInSelector, userPlanTypeSelector } from 'selectors/userSelectors';

const IndexContainer = () => {
    const dispatch = useDispatch();

    const firstTrackingId = useSelector(firstTrackingIdSelector);
    const trackingCount = useSelector(trackingCountSelector);
    const trackingsFetched = useSelector(trackingsFetchedSelector);
    const userFetched = useSelector(fetchedSelector);
    const userLimitsFetched = useSelector(limitsFetchedSelector);
    const userLoggedIn = useSelector(loggedInSelector);

    const isUserFetched = () => userFetched && userLimitsFetched;

    const handleRedirect = () => {
        if (isUserFetched()) {
            if (trackingsFetched) {
                if (trackingCount === 0) {
                    dispatch(requestedNavigationReplaceAction(RoutePaths.NEW, {}));
                } else if (trackingCount === 1) {
                    dispatch(requestedNavigationReplaceAction(RoutePaths.TRACKING, { id: firstTrackingId }));
                } else {
                    dispatch(requestedNavigationReplaceAction(RoutePaths.TRACKINGS, {}));
                }
            }
        }
    };

    useEffect(() => {
        handleRedirect();
    }, [userFetched, userLimitsFetched, trackingsFetched, trackingCount, firstTrackingId, dispatch]);

    if (isUserFetched() && trackingsFetched) {
        return null;
    } else {
        if (userLoggedIn) {
            return (
                <div className="uk-position-cover uk-flex uk-flex-middle uk-flex-center">
                    <Preloader />
                </div>
            );
        } else {
            return null;
        }
    }
};

export default IndexContainer;
