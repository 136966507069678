import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHolder from 'components/messages/MessageHolder';
import { useDispatch, useSelector } from 'react-redux';
import Urls from 'mangools-commons/dist/constants/Urls';
import { upsellVisibilitySelector } from 'selectors/uiSelectors';
import { closeUpsellMessage } from 'actions/uiActions';

const UpsellMessage = (): ReactElement | null => {
    const dispatch = useDispatch();
    const isVisible = useSelector(upsellVisibilitySelector);
    const onClose = () => {
        dispatch(closeUpsellMessage());
    };
    if (isVisible) {
        return (
            <MessageHolder onClose={onClose}>
                <div className="mg-modal-header is-info uk-flex uk-flex-middle uk-flex-center">
                    <FontAwesomeIcon icon="map-marker-alt" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Add location</h3>
                    <p className="uk-margin-remove">Get the detailed view on your local rankings.</p>
                    <p className="uk-margin-remove">Create tracking for multiple locations. </p>
                    <div className="mg-margin-t-15">
                        <a
                            href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?ref=locations-upsell-modal`}
                            className="mg-btn is-green is-small"
                            type="button"
                            target="_blank"
                            style={{ minWidth: '153px' }}
                        >
                            See plans
                        </a>
                    </div>
                    <p className="font-12">This feature is available in Basic, Premium and Agency plans.</p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
};

export default UpsellMessage;
