import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import { SourceService } from 'mangools-commons/dist/services/SourceService';
import HumanService from 'mangools-commons/dist/services/HumanService';
import { parseGsc } from 'sources/parsers/GscDataParser';
import queryString from 'query-string';
import { API_VERSION, APP_CONFIG } from 'mangools-commons/dist/configs/app';

// Constants
const ENDPOINT = '/mangools/google/search-console/get-kws';

/**
 * Data source class handling communication with API for keyword lists data.
 */
class GscSource extends SourceService {
    static getData(accessToken, domain, locationIds) {
        const query = queryString.stringify({
            domain,
        });
        const requestURL = `${APP_CONFIG.API_HOST}/${API_VERSION}${ENDPOINT}?${query}&locationIds=${locationIds}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parseGsc(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default GscSource;
