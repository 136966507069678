// const PARSED_TRACKINGS_EXAMPLE = [{
//     createdAt: DATE,
//     domain: 'kwfinder.com',
//     location: {
//         id: 0,
//         code: null,
//         label: 'Anywhere'
//     },
//     id: '2s3a1hbjd123asd31',
//     platformId: 1,
//     timeframes: {
//         '2017-02-06': {
//             estimatedVisits: 205,
//             performanceIndex: 10.2312
//         },
//         '2017-02-05': {
//             estimatedVisits: 150,
//             performanceIndex: 8.2101
//         }
//     },
// }];

import {
    parseFloatWithNAFallback,
    parseIntWithNAFallback,
    parseIntWithNVFallback,
    parseLocationLabel,
    parseStringWithNAFallback,
} from 'sources/parsers/commons';

import Defaults from 'mangools-commons/dist/constants/Defaults';

import { isNil, assocPath, hasPath, pipe } from 'ramda';
import map from 'ramda/src/map';
import AnnotationTypes from 'constants/AnnotationTypes';

import { parse as parseReports } from './ReportDataParser';

const parseLocation = rawLocation => ({
    id: rawLocation._id, // eslint-disable-line no-underscore-dangle
    code: rawLocation.code,
    label: parseLocationLabel(rawLocation.label),
});

const parseTimeframes = rawItem => {
    const { stats } = rawItem;

    if (!isNil(stats) && !isNil(stats.timeframes)) {
        return map(
            tf => ({
                estimatedVisits: parseIntWithNAFallback(tf.estimated_visits),
                performanceIndex: parseFloatWithNAFallback(tf.performance_index),
            }),
            stats.timeframes,
        );
    } else {
        return [];
    }
};

export const parseTracking = rawTracking => ({
    createdAt: rawTracking.created_at,
    domain: rawTracking.domain,
    id: rawTracking._id, // eslint-disable-line no-underscore-dangle
    trackedKeywordsCount: rawTracking.count ? parseInt(rawTracking.count, 10) : 0,
    location: rawTracking.location ? parseLocation(rawTracking.location) : null,
    trackingConfig: rawTracking.tracking_config,
    platformId: rawTracking.platform_id ? parseIntWithNVFallback(rawTracking.platform_id) : null,
    timeframes: parseTimeframes(rawTracking),
    reportsActive: rawTracking.reports_active || [],
    isDeleted: rawTracking.is_deleted || false,
});

export const parse = rawTrackings => rawTrackings.map(parseTracking);

export const parseCreate = rawResponse => ({
    postponedProcessing: rawResponse.postponed_processing,
    estimatedTimeProcessing: rawResponse.estimatedTimeProcessing,
    data: {
        createdAt: rawResponse.tracking.created_at,
        domain: rawResponse.tracking.domain,
        id: rawResponse.tracking._id, // eslint-disable-line no-underscore-dangle
        trackedKeywordsCount: rawResponse.tracking.count ? parseInt(rawResponse.tracking.count, 10) : 0,
        location: parseLocation(rawResponse.tracking.location),
        trackingConfig: rawResponse.tracking.tracking_config,
        platformId: parseIntWithNVFallback(rawResponse.tracking.platform_id),
        timeframes: parseTimeframes(rawResponse),
        trackings: Array.isArray(rawResponse.tracking) ? parse(rawResponse.tracking) : parse([rawResponse.tracking]),
    },
    reports: parseReports(rawResponse.reports),
});

const parseTags = rawTags =>
    rawTags.map(rawTag => ({
        color: rawTag.color,
        createdAt: rawTag.created_at,
        id: rawTag._id, // eslint-disable-line no-underscore-dangle
        name: rawTag.name,
    }));

const parseMapPack = mapPack => ({
    rank: mapPack?.rank || null,
    hasUrl: !!mapPack?.hasUrl,
});

const parseFeaturedSnippet = featuredSnippet => ({
    serpRank: featuredSnippet?.rank || null,
    url: !!featuredSnippet?.url,
    isRanking: featuredSnippet?.isRanking,
});

const parseKeywords = (rawKeywords = []) =>
    rawKeywords.map(keyword => ({
        createdAt: keyword.created_at,
        isDeleted: keyword.is_deleted,
        id: keyword._id, // eslint-disable-line no-underscore-dangle
        keyword: keyword.kw,
        lastCheckedAt: keyword.last_checked_at,
        mapPack: parseMapPack(keyword.map_pack),
        featuredSnippet: parseFeaturedSnippet(keyword.feat_snippet),
        rank: Defaults.NOT_AVAILABLE,
        rankAvg: Defaults.NOT_AVAILABLE,
        rankBest: Defaults.NOT_AVAILABLE,
        rankChange: Defaults.NOT_AVAILABLE,
        estimatedVisitsChange: Defaults.NO_VALUE,
        performanceIndexChange: Defaults.NO_VALUE,
        estimatedVisitsTotal: null,
        estimatedVisits: null,
        searchVolume: null,
        rankHistory: [Defaults.NOT_AVAILABLE, Defaults.NOT_AVAILABLE, Defaults.NOT_AVAILABLE],
        tags: parseTags(keyword.tags),
        url: parseStringWithNAFallback(keyword.url),
        isMoreUrl: keyword.isMoreUrl,
        visualMetrics: keyword.visualMetrics,
        isEmptyMainArray: keyword.isEmptyMainArray,
        nearestBeforeDay: keyword.nearestBeforeDay,
        serpFeatures: keyword.serpFeatures,
    }));

const parseAnnotationType = rawType => {
    switch (rawType) {
        case 0: {
            return AnnotationTypes.USER;
        }
        case 1: {
            return AnnotationTypes.VOLATILITY;
        }
        case 2: {
            return AnnotationTypes.KW_CHANGE_ADD;
        }
        case 3: {
            return AnnotationTypes.KW_CHANGE_REMOVE;
        }
        case 4: {
            return AnnotationTypes.GOOGLE;
        }
        case 5: {
            return AnnotationTypes.SYSTEM;
        }
        default: {
            return AnnotationTypes.USER;
        }
    }
};

export const parseDetail = rawTracking => {
    const data = {
        createdAt: rawTracking.tracking.created_at,
        domain: rawTracking.tracking.domain,
        id: rawTracking.tracking._id, // eslint-disable-line no-underscore-dangle,
        trackingConfig: rawTracking.tracking.tracking_config,
        keywords: rawTracking.keywords ? parseKeywords(rawTracking.keywords) : [],
        location: rawTracking.tracking.location ? parseLocation(rawTracking.tracking.location) : null,
        platformId: rawTracking.tracking.platform_id ? parseIntWithNVFallback(rawTracking.tracking.platform_id) : null,
        shareToken: rawTracking.tracking.share_token,
        timeframePoints: rawTracking.history_dates,
    };

    const whiteLabelLogoPath = ['whiteLabel', 'logo'];
    const whiteLabelAccentColorPath = ['whiteLabel', 'accentColor'];
    const whiteLabelEnabledPath = ['whiteLabel', 'enabled'];
    const whiteLabelNamePath = ['whiteLabel', 'name'];
    const whiteLabelWebsitePath = ['whiteLabel', 'website'];

    return pipe(
        parsedData =>
            assocPath(
                whiteLabelLogoPath,
                hasPath(whiteLabelLogoPath, rawTracking) && !isNil(rawTracking.whiteLabel.logo)
                    ? rawTracking.whiteLabel.logo
                    : null,
                parsedData,
            ),
        parsedData =>
            assocPath(
                whiteLabelNamePath,
                hasPath(whiteLabelNamePath, rawTracking) && !isNil(rawTracking.whiteLabel.name)
                    ? rawTracking.whiteLabel.name
                    : null,
                parsedData,
            ),
        parsedData =>
            assocPath(
                whiteLabelWebsitePath,
                hasPath(whiteLabelWebsitePath, rawTracking) && !isNil(rawTracking.whiteLabel.website)
                    ? rawTracking.whiteLabel.website
                    : null,
                parsedData,
            ),
        parsedData =>
            assocPath(
                whiteLabelAccentColorPath,
                hasPath(whiteLabelAccentColorPath, rawTracking) && !isNil(rawTracking.whiteLabel.accentColor)
                    ? `${rawTracking.whiteLabel.accentColor}`
                    : null,
                parsedData,
            ),
        parsedData =>
            assocPath(
                whiteLabelEnabledPath,
                hasPath(whiteLabelEnabledPath, rawTracking) && !isNil(rawTracking.whiteLabel.enabled)
                    ? rawTracking.whiteLabel.enabled
                    : false,
                parsedData,
            ),
    )(data);
};

export const parseUpdate = rawResponse => ({
    tracking: {
        domain: rawResponse.tracking.domain,
        id: rawResponse.tracking._id, // eslint-disable-line no-underscore-dangle
    },
    annotation: {
        id: rawResponse.annotation._id, // eslint-disable-line no-underscore-dangle
        date: rawResponse.annotation.date,
        type: parseAnnotationType(rawResponse.annotation.type),
        nonShareable: rawResponse.annotation.nonShareable,
        content: {
            avg: parseFloatWithNAFallback(rawResponse.annotation.content.avg),
            keywordsCount: parseIntWithNAFallback(rawResponse.annotation.content.keywords_count),
            text: parseStringWithNAFallback(rawResponse.annotation.content.text),
        },
    },
});

export const parseShareTokenUpdate = rawResponse => ({
    shareToken: rawResponse.share_token,
});
