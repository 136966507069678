import React, { useEffect, useState, useCallback } from 'react';
import { func } from 'prop-types';
import moment from 'moment';
import isNil from 'ramda/src/isNil';
import { DayPicker } from 'react-day-picker';
import { MAXIMUM_DAYS } from 'constants/TimeframePresets';
import MomentType from 'types/commons/MomentType';
import { enGB } from 'date-fns/locale';

const DateRangePicker = ({ from, to, start, onChange }) => {
    const validateDate = useCallback((date) => {
        if (moment.isMoment(date) && date.isValid()) {
            return date.toDate();
        }
        return null;
    }, []);

    const [range, setRange] = useState(() => ({
        from: validateDate(from),
        to: validateDate(to),
    }));

    useEffect(() => {
        setRange({
            from: validateDate(from),
            to: validateDate(to),
        });
    }, [from, to, validateDate]);

    const getFirstAvailableMonth = useCallback(() => {
        if (!isNil(start)) {
            return start.startOf('day').toDate();
        }
        return moment().subtract(MAXIMUM_DAYS, 'days').startOf('day').toDate();
    }, [start]);

    const getTodayMonth = useCallback(() => {
        return moment().startOf('month').toDate();
    }, []);

    const getPreviousMonth = useCallback(() => {
        return moment().subtract(1, 'month').startOf('month').toDate();
    }, []);

    const getModifiers = useCallback(() => {
        let before = null;
        const s = !isNil(start) ? start.startOf('day') : null;
        if (!isNil(s)) {
            const maximumDay = moment().subtract(MAXIMUM_DAYS, 'days').startOf('day');
            before = s.isBefore(maximumDay) ? maximumDay.toDate() : s.toDate();
        }
        return {
            disabled: [{ before }, { after: moment().endOf('day').toDate() }],
            from: range.from,
            selected: { from: range.from, to: range.to },
            to: range.to,
        };
    }, [start, range]);

    const handleDayClick = useCallback(
        (day, { disabled = false }) => {
            const { from: stateFrom, to: stateTo } = range;
            if (!disabled) {
                if (stateFrom && day < stateFrom) {
                    setRange({ from: day, to: null });
                    onChange({ from: moment(day), to: null });
                } else if (stateFrom && !stateTo) {
                    setRange({ ...range, to: day });
                    onChange({ from: moment(stateFrom), to: moment(day) });
                } else if (stateFrom && stateTo) {
                    setRange({ from: day, to: null });
                    onChange({ from: moment(day), to: null });
                }
            }
        },
        [range, onChange]
    );

    return (
        <DayPicker
            locale={enGB}
            firstDayOfWeek={1}
            className="Range"
            classNames={{
                root: 'DayPicker DayPicker-wrapper',
                month: 'DayPicker-Month',
                row: 'DayPicker-Week',
                head_row: 'DayPicker-WeekdaysRow',
                head_cell: 'DayPicker-Weekday',
                day: 'DayPicker-Day',
                caption: 'DayPicker-Caption',
                nav: 'DayPicker-NavBar',
                nav_button: 'DayPicker-NavButton',
                nav_button_previous: 'DayPicker-NavButton DayPicker-NavButton--prev',
                nav_button_next: 'DayPicker-NavButton DayPicker-NavButton--next',
            }}
            defaultMonth={getPreviousMonth()}
            startMonth={getFirstAvailableMonth()}
            endMonth={getTodayMonth()}
            initialMonth={range.from || getFirstAvailableMonth()}
            numberOfMonths={2}
            onDayClick={handleDayClick}
            modifiers={getModifiers()}
            modifiersClassNames={{
                outside: 'DayPicker-Day--outside',
                disabled: 'DayPicker-Day--disabled',
                selected: 'DayPicker-Day--selected',
                from: 'DayPicker-Day--from',
                to: 'DayPicker-Day--to',
            }}
        />
    );
};

DateRangePicker.propTypes = {
    from: MomentType.isRequired,
    to: MomentType,
    start: MomentType,
    onChange: func.isRequired,
};

export default DateRangePicker;
