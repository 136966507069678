import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import queryString from 'query-string';
import { parse } from 'sources/parsers/LocationDataParser';

// Constants
const ENDPOINT = '/locations';

/**
 * Data source class handling retrieval of location data from query.
 */
class LocationSource {
    /**
     * gets data from location source
     *
     * @static
     * @param {string} query user query which should match locations
     */
    static getData({ accessToken, query }) {
        const requestQuery = queryString.stringify({ query });
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.KWFINDER_API_PATH}${ENDPOINT}?${requestQuery}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parse(data),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default LocationSource;
